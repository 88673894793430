import { Link, graphql } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import * as Contentstyle from '../../components/content/content.module.scss'
import * as style from '../../components/album/album.module.scss'
import Content from '../../components/content/content'
import Img from "gatsby-image"
import React from 'react'

const TionnoEP = ( {data} ) => (
  <ModalRoutingContext.Consumer>
    {({ modal, closeTo }) => (
      <div className={style.albumDetails}>
        {modal ? (
          <Link to={closeTo}>
            <svg className={style.albumDetails__close} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.53553 0.464768C7.92606 0.855293 7.92606 1.48846 7.53553 1.87898L5.41421 4.0003L7.53553 6.12162C7.92606 6.51215 7.92606 7.14531 7.53553 7.53584C7.14501 7.92636 6.51184 7.92636 6.12132 7.53584L4 5.41452L1.87868 7.53584C1.48815 7.92636 0.854988 7.92636 0.464463 7.53584C0.0739387 7.14531 0.0739387 6.51215 0.464463 6.12162L2.58578 4.0003L0.464463 1.87898C0.073939 1.48846 0.0739387 0.855293 0.464463 0.464768C0.854988 0.0742437 1.48815 0.0742441 1.87868 0.464768L4 2.58609L6.12132 0.464768C6.51184 0.0742441 7.14501 0.0742437 7.53553 0.464768Z" />
            </svg>
          </Link>
        ) : (
          <div>
            {/* Not sure */}
          </div>
        )}

          <Content extraClass='content--modal'>
            <div className="column column--40">
              <div className="sticky">

                <Img className={style.albumDetails__cover} fluid={data.cover.childImageSharp.fluid} />

                <p className={style.albumDetails__credits}>                  
                  <strong>Executive Producer:</strong> Specyal T <br/>
                  <strong>Label:</strong> Independent<br/>
                  <strong>Recorded At:</strong> Massive Sound Studios <br/>
                  <strong>Production Provided By:</strong> Foley<br/>
                  <strong>Mixed & Mastered By:</strong> Simon Bader
                </p>
                
                <div className={Contentstyle.subtitleLowercase}>Tracks</div>

                <ol className={style.albumDetails__tracks}>
                  <li>Side Effects <a href="https://youtu.be/MXSdUVlcpxA" target="_blank" rel="noreferrer">YouTube</a></li>
                  <li>Superwoman Tendencies</li>
                  <li>2 AM </li>
                  <li>Forever And A Day</li> 
                  <li>Scar Tissue </li>
                </ol>
              </div>

            </div>
            <div className="column column--60">
            <span className={Contentstyle.titleLowercase}>Track Synopsis</span>



            <p><strong>Side Effects</strong><br/>
            Discussing from a first hand account, the daily struggle of what a pregnant mom goes through when suffering from the debilitating effects of the potentially life threatening disease known as Hyperemesis Gravidarum (HG).</p>
            <hr/>
            <p><strong>Superwoman Tendencies</strong><br/>
            Celebrating all the strong, brave and courageous women out there who currently have or have been through HG.</p>
            <hr/>
            <p><strong>2 AM</strong><br/>
            Chronicling the morning my water broke. Tionne arrived the following evening.</p>
            <hr/>
            <p><strong>Forever And A Day</strong><br/>
            Chronicling the day Tionne passed away, the second and final time I held her in my arms.</p>
            <hr/>
            <p><strong>Scar Tissue</strong><br/>
            Life after Tionne's passing, dealing with other's emotions and opinions, and the shocking disrespect of my family's very personal situation by those who were once close to us!</p>

           </div>

          </Content>

      </div>
    )}
  </ModalRoutingContext.Consumer>
)

export default TionnoEP


export const query = graphql`
query {
  cover: file(relativePath: {eq: "st-store/albums/tionne-ep.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
       } 
    }
  }
}
`